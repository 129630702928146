import React, { useEffect } from "react";
import AppLayout from "components/Layout/AppLayout";
import AppMenu from "components/Base/AppMenu";
import AppTextArea from "components/Base/AppTextArea";
import AppButton from "components/Base/AppButton";
import { useNavigate } from "react-router-dom";
import { prompt, like, Answer, getPlaceholderQuestionExamples, limits, AnswerLimits } from "services/answers";
import { useState } from "react";
import AnswerCardFull from "components/Answer/AnswerCardFull";
import CounterBadge from "components/Base/CounterBadge";



const DashboardPage = () => {
  const navigate = useNavigate();

  const initPlaceholder = 'Ask me a question by typing it here, that is related to your life or your goals';
  const [state, setState] = useState<{ question: string; answer: Answer | undefined; isLoading: boolean; placeholder: string; limits: AnswerLimits | undefined }>(
    {
      question: "",
      answer: undefined,
      isLoading: false,
      placeholder: initPlaceholder,
      limits: undefined
    });

  const getPlaceholder = async (numberOfQuestions: number): Promise<string> => {
    
    try {
      const response = await getPlaceholderQuestionExamples(numberOfQuestions);
      const body = response.map((question: string) => question).join("\n\n");
      return `${initPlaceholder}, like:\n\n${body}`;
    } catch (error) {
      return initPlaceholder;
    }
  }

  useEffect(() => {
    if(!state.answer){
      const placeholder = getPlaceholder(2);
      placeholder.then((result: string) => {
        setState({...state, placeholder: result});
      });
    }

    if(!state.limits){
      limits().then((res: AnswerLimits) => setState({...state, limits: res}));
    }

  }, [state.answer, state.limits]);

  const handlePrompt = async () => {
    setState({...state, isLoading: true});
    let answer: Answer | undefined = undefined;
    if(state.question && state.question.length > 0) {
      answer = await prompt(state.question);
    }
  
    if (answer) {
      setState({...state, answer, isLoading: false, limits: {...answer.answerLimits} });
    }
    else {
      const questions = await getPlaceholder(3);
      setState({...state, placeholder: questions, isLoading: false });

    }
  };

  const handleLikeAnswer = async () => {
    if (!state.answer) return;
    let isLiked = await like(state.answer.answerID, !state.answer.isLiked);
    setState({ ...state, answer: { ...state.answer, isLiked }});
  };

  const outOfAnswers = state.limits && (state.limits.answerCount  >= state.limits.answerLimit);

  return (
    <AppLayout>
      <div className="flex flex-col items-center w-full gap-5">
        {state.answer ? (
          <AnswerCardFull
            answer={state.answer}
            onDismiss={() => setState({...state, limits: state.answer && {...state.answer.answerLimits}, answer: undefined})}
            handleLike={handleLikeAnswer}
          />
        ):(
          <>
            <p className="text-2xl font-semibold leading-10 text-center max-w-[320px] lg:max-w-none">
            What does your soul<br/>seek to know?
            </p>
            <div className="max-w-[350px] relative">
              <AppTextArea
                className="pb-20"
                placeholder={state.placeholder}
                onChange={(e) => {
                  setState({...state, question: e.target.value })
                }}
              />
              <AppButton
                onClick={handlePrompt}
                className="w-[120px] border-[3px] border-solid border-white h-[120px] px-0 py-0 rounded-full items-center !absolute bottom-[-60px] left-1/2 transform -translate-x-1/2"
                hideIcon
                glow={state.isLoading}
                disabled={outOfAnswers}
              >
                {state.isLoading ? "Thinking" : (outOfAnswers ? "Quota Exceeded" : "Ask")}
              </AppButton>
              <div className="absolute bottom-[-26px] right-[16px]">
                <CounterBadge 
                  current={state.limits?.answerCount || 0} 
                  limit={state.limits?.answerLimit || 0} 
                  cooldownDurationSec={state.limits?.answerResetPeriodSec || 0}
                  onTimerEnd={() => {
                    limits().then((res: AnswerLimits) => setState({...state, limits: res}));
                  }} />
              </div>
            </div>
          </>
        )}
        <div className="mt-20 h-fit w-full justify-center flex flex-cols items-center">
          <AppMenu />
        </div>
      </div>
    </AppLayout>
  );
};

export default DashboardPage;
