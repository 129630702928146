import React, { useState, useEffect } from 'react';
import './css/CounterBadge.css'
import { twMerge } from 'tailwind-merge';

const CounterBadge = (props: { current: number, limit: number, cooldownDurationSec: number,  onTimerEnd: () => void }) => {

  const [timeLeft, setTimeLeft] = useState<number>(props.cooldownDurationSec);

  useEffect(() => {
    let interval: any;
    if(timeLeft === 0 && props.cooldownDurationSec > 0) {
      interval && clearInterval(interval);
      setTimeLeft(props.cooldownDurationSec);
    }

    if ((props.current >= props.limit) && (timeLeft > 0)) {
      interval = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            props.onTimerEnd();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [props.current, props.limit, timeLeft, props.cooldownDurationSec]);

  const formatTime = () => {
    if (timeLeft > 86400) {
        return `${Math.ceil(timeLeft / 86400)}d`;
    }else if (timeLeft > 3600) {
      return `${Math.ceil(timeLeft / 3600)}h`;
    } else if (timeLeft > 60) {
      return `${Math.ceil(timeLeft / 60)}m`;
    } else {
      return `${timeLeft}s`;
    }
  };

  const isTimer = (props.current >= props.limit) && (timeLeft > 0);
  //console.log({isTimer, current: props.current, limit: props.limit, timeLeft});
  const commonStyles = 'w-14 h-14 rounded-full border-2 border-solid flex items-center justify-center text-lg bg-blue-200 shadow-lg transition-colors'
  const classNames = twMerge(commonStyles, (isTimer ? 'animate-pulse-glow' : 'border-blue-400'));

  return (
    <div
      className={classNames}
    >
      {isTimer ? formatTime() : `${props.current}/${props.limit}`}
    </div>
  );
};

export default CounterBadge;
