import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { register } from "./serviceWorkerRegistration";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);


if ('serviceWorker' in navigator) {
  // Register a service worker hosted at the root of the
  // site using the default scope.

  // call register with the proper arguments
  register({
    onUpdate: (registration) => {
      const pushState = window.history.pushState;
      window.history.pushState = function (state, title, url) {

        pushState.apply(window.history, [state, title, url]);

        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        
        installingWorker.postMessage({ type: 'SKIP_WAITING' });
      }
  }});

  // navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
  //   registration => {
  //     console.log('Service worker registration succeeded:', registration);
  //   },
  //   /*catch*/ error => {
  //     console.error(`Service worker registration failed: ${error}`);
  //   }
  // );
  // navigator.serviceWorker.addEventListener('controllerchange', () => {
  //   window.location.reload();
  // });
} else {
  console.error('Service workers are not supported.');
}

