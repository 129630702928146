import AppTextField from "components/Base/AppTextfield";
import AppButton from "components/Base/AppButton";
import AppRadioGroup from "components/Base/AppRadioGroup";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createProfile, getProfile, updateProfile } from "services/profile";
import { useEffect, useState } from "react";
import App from "App";

const AboutYouForm = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    dateOfBirth: Yup.string()
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
      'Date must be in MM/DD/YYYY format'
    ).required('Date is required'),
    gender: Yup.string().required("Gender is required"),
  });

  const [isNew, setIsNew] = useState(true);
  const [onsubmitNavTo, setOnSubmitNavTo] = useState('/dashboard');
  const [initialValues, setInitialValues] = useState({
    name: "",
    dateOfBirth: "",
    gender: "male",
  });

  useEffect(() => {
    getProfile().then(p => {
      if (!p) return;

      setIsNew(false);
      setInitialValues({
        name: p.name,
        dateOfBirth: convertDate(p.dateOfBirth),
        gender: p.gender
      });
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      //console.log("Form values:", values);

      const p = {
        name: values.name,
        dateOfBirth: formatDate(values.dateOfBirth),
        gender: values.gender
      }

      if (isNew) {
        await createProfile(p);
      } else {
        await updateProfile(p);
      }

      navigate(onsubmitNavTo);
    },
  });

  return (
    <div className="w-full mx-auto flex flex-col items-center gap-5 max-w-[280px]">
      <p className="text-[22px] font-semibold leading-[33px] text-center mb-2">
        About You
      </p>

      <form
        onSubmit={formik.handleSubmit}
        className="max-w-[280px] mx-auto flex flex-col gap-[20px] mt-2"
      >
        <div>
          <AppTextField
            placeholder="Enter Name"
            className="h-[54px] rounded-[60px]"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.name}
            </div>
          ) : null}
        </div>

        <div>
          <AppTextField
            placeholder="Date of Birth"
            className="h-[54px] rounded-[60px]"
            name="dateOfBirth"
            type="text"
            value={formik.values.dateOfBirth}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
            <div className="text-red-500 text-center mt-1 text-xs">
              {formik.errors.dateOfBirth}
            </div>
          ) : null}
        </div>

        <div className="flex w-full gap-[2px] flex-wrap items-center justify-between">
          <AppRadioGroup
            name="gender"
            options={[
              { label: "Male", value: "male" },
              { label: "Female", value: "female" },
            ]}
            value={formik.values.gender}
            onChange={(value) => formik.setFieldValue("gender", value)}
          />
          {formik.touched.gender && formik.errors.gender ? (
            <div className="text-red-500 text-center mt-1 text-sm w-full">
              {formik.errors.gender}
            </div>
          ) : null}
        </div>

        <AppButton
          className="h-[54px] rounded-[60px] w-full max-w-[280px] mx-auto mt-10"
          type="button"
          color="primary"
          hideIcon
          variant="default"
          onClick={() => {
            setOnSubmitNavTo("/core-values");
            formik.submitForm();
          }}
        >
          Core Values
        </AppButton>

        <AppButton
          className="h-[54px] rounded-[60px] w-full max-w-[280px] mx-auto mt-5"
          type="submit"
        >
          Continue
        </AppButton>
      </form>
      
      <AppButton
        className="h-[54px] rounded-[60px] w-full max-w-[280px] mx-auto mt-5"
        type="button"
        color="secondary"
        hideIcon
        variant="subdued"
        onClick={() => {
          localStorage.removeItem("token");
          navigate("/login");
        }}
      >
        Logout
      </AppButton>

    </div>
  );
};

const formatDate = (input: string) => {
  const date = new Date(input);
  return date.toISOString();
};

const convertDate = (dateStr: string) => {
  const [date] = dateStr.split('T');
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
};

export default AboutYouForm;
