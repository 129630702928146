import React from "react";
import ArrowRightImg from "assets/images/arrow-right.svg";
import { twMerge } from "tailwind-merge";
import './css/GlowingButton.css'
import { warn } from "console";

type ButtonVariant = "default" | "outline" | "text" | "warn" | "subdued";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  hideIcon?: boolean;
  glow?: boolean;
}

const AppButton: React.FC<ButtonProps> = ({
  variant = "default",
  children,
  className,
  hideIcon = false,
  glow = false,
  ...props
}) => {
  const buttonStyles = {
    default: "primary-gradient",
    outline: "bg-white",
    text: "",
    warn: "warn-gradient",
    subdued: "bg-subdued",
  };

  const iconStyles = {
    default: "",
    outline: "primary-gradient",
    text: "",
    warn: "warn-gradient",
    subdued: "bg-subdued",
  };

  const commonStyles =
    "rounded-full px-20 py-2 text-lg font-semibold leading-[27px] relative text-tertiary";

  const classNames = twMerge(commonStyles, buttonStyles[variant], className, (glow && 'glow'));

  const commonIconStyles =
    "bg-white bg-opacity-20 absolute right-0 mr-2 top-1/2 transform -translate-y-1/2 w-[34px] h-[34px] flex items-center justify-center rounded-full";

  const iconClassNames = twMerge(commonIconStyles, iconStyles[variant]);

  return (
    <button className={classNames} {...props}>
      {children}
      {!hideIcon && (
        <div className={iconClassNames}>
          <img src={ArrowRightImg} alt="arrow-right" />
        </div>
      )}
    </button>
  );
};

export default AppButton;
