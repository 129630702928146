import { Routes, Route } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import WelcomePage from "pages/WelcomePage";
import AboutYouPage from "pages/AboutYouPage";
import DashboardPage from "pages/DashboardPage";
import SubscriptionPage from "pages/SubscriptionPage";
import ProtectedRoutes from "./ProtectedRoutes";
import AnswersPage from "pages/AnswersPage";
import SubscriptionConfirmPage from "pages/SubscriptionConfirmPage";
import ExternalAuthLanding from "pages/ExternalAuthLanding";
import CoreValues from "pages/CoreValues";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="answers" element={<AnswersPage />} />
        <Route path="subscription" element={<SubscriptionPage />} />
        <Route path="about-you" element={<AboutYouPage />} />
        <Route path="core-values" element={<CoreValues/>} />
        <Route path="success" element={<SubscriptionConfirmPage event={"success"} />} />
        <Route path="cancel" element={<SubscriptionConfirmPage event={"cancel"} />} />
      </Route>
      <Route path="/" element={<WelcomePage />} />
      <Route path="welcome" element={<WelcomePage />} />
      <Route path="login" element={<LoginPage form={"login"} />} />
      <Route path="register" element={<LoginPage form={"register"} />} />
      <Route path="forgot-password" element={<LoginPage form={"reset"} />} />
      <Route path="confirm-reset" element={<LoginPage form={"confirm-reset"} />} />
      <Route path="confirm" element={<LoginPage form={"confirm"} />} />
      <Route path="check-email" element={<LoginPage form={"check-email"} />} />
      <Route path="external-auth" element={<ExternalAuthLanding />} />
      
    </Routes>
  );
}

export default AppRoutes;
