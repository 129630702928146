import apiInstance from "services";

export const getAnswers = async (page: number, pageSize: number): Promise<AnswerArchived[]> => {
  try {
    const response = await apiInstance.get(`/api/answer/get-answers?page=${page}&pageSize=${pageSize}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAnswersCount = async (): Promise<number> => {
  try {
    const response = await apiInstance.get(`/api/answer/get-answers-count`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const prompt = async (Question: string): Promise<Answer | undefined> => {
  try {
    const response = await apiInstance.post("/api/answer/prompt", {
      Question,
    });
    return response.data;
  } 
  catch (error) {
    console.error(error);
    throw error;
  }
};

export const like = async (id: number, isLiked: boolean): Promise<boolean> => {
  try {
    const response = await apiInstance.post("/api/answer/like", {
      AnswerId: id,
      IsLiked: isLiked
    });
    return response.status === 200 ? isLiked : !isLiked;
  } 
  catch (error) {
    console.error(error);
    return !isLiked;
  }
};

export const limits = async (): Promise<AnswerLimits> => {
  try {
    const response = await apiInstance.get("/api/answer/limits");
    return response.data;
  } 
  catch (error) {
    console.error(error);
    throw error;
  }
};

const placeholderQuestions = [
  'How should I process my thoughts and actions today in order to bring me closer to my true self?',
  'How can I cultivate gratitude in the face of challenges?',
  'What is the most important thing I can do today to improve my life?',
  'What can i do to cultivate inner peace, and how can I nurture it?',
  'In what ways can I embrace the present moment more fully?',
  'What lessons am I learning from <<experience you had>>?',
  'How can I let go of what no longer serves my growth?',
  'Given my goal of <<goal>>, What intentions do I want to set for my journey?',
  'How can I show compassion to myself and others today?',
  'My fear of <<fear>> is holding me back, how can I overcome it?',
  'My core values are <<core values>>, how can I cultivate them in my life?',
]

const getMockPlaceholderQuestion = async (numberOfQuestions: number): Promise<string[]> => {

  const randomItems = placeholderQuestions.sort(() => Math.random() - 0.5).slice(0, Math.min(placeholderQuestions.length, numberOfQuestions));
  return Promise.resolve(randomItems);

}

export const getPlaceholderQuestionExamples = async (numberOfQuestions: number): Promise<string[]> => {
  try {
    //const response = await apiInstance.get("/api/answer/placeholder-question-examples");
    //return response.data;
    return getMockPlaceholderQuestion(numberOfQuestions);
  } 
  catch (error) {
    console.error(error);
    throw error;
  }
};

export interface Answer {
  answerID: number;
  question: string;
  answer: string;
  isLiked: boolean;
  createdAtUtc: string;
  answerLimits: AnswerLimits
}

export interface AnswerArchived {
  answerID: number;
  question: string;
  answer: string;
  isLiked: boolean;
  createdAtUtc: string;
}

export interface AnswerLimits {
  answerLimit: number;
  answerCount: number;
  answerResetPeriodSec: number;
  canAnswer: boolean;
}
