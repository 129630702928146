
import apiInstance from "services";

export const getCoreValues = async () : Promise<CoreValue[] | null> => {
  try {
    const response = await apiInstance.get("/api/CoreValues/get");
    if (response.status === 200) {
      return response.data;
    }
    
    return null;

  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createCoreValue = async (coreValue: CoreValue): Promise<CoreValue> => {
  try {
    const response = await apiInstance.post("/api/CoreValues/create", coreValue);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateCoreValue = async (coreValue: CoreValue): Promise<boolean> => {
  try {
    const response = await apiInstance.put("/api/CoreValues/update", coreValue);
    return response.status === 200;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteCoreValue = async (coreValueID: number): Promise<boolean> => {
    try {
      const response = await apiInstance.delete("/api/CoreValues/delete?id=" + coreValueID);
      return response.status === 200;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export interface CoreValue {
  id: number | null;
  value: string;
  updatedAtUtc?: string;
}


