import { useState, useEffect } from "react";
import AppLayout from "components/Layout/AppLayout";
import SimpleTextCard from "components/Base/SimpleTextCard";

import NewItemIcon from "assets/images/new-item.svg";

import { CoreValue, createCoreValue, deleteCoreValue, getCoreValues, updateCoreValue } from "services/core-values";

const CoreValues = () => {
  const [coreValues, setCoreValues] = useState<CoreValue[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getCoreValues().then((res) => setCoreValues(res || []));
  }, []);

  const updateArrayItem = (coreValue: CoreValue, isNew?: boolean) => {
    const cvId = isNew ? null : coreValue.id;
    var index = coreValues.findIndex(x => x.id === cvId);
    if (index === -1)
      // handle error
      console.log("Core Value not found");
  
    else
      setCoreValues(
        [
          ...coreValues.slice(0, index),
          { ...coreValues[index], value: coreValue.value },
          ...((index+1 < coreValues.length) ? coreValues.slice(index+1) : [])
        ]
      );
    return index;
  }

  const handleNewCoreValue = async () => {
    const newCoreValue: CoreValue = {
      id: null,
      value: "",
    };

    setCoreValues([...coreValues, newCoreValue]);
  };

  const handleUpdateCoreValue =async (coreValue: CoreValue) =>{
    if(coreValue.id === null){
      try {
        const created = await createCoreValue(coreValue);
        if(!created){
          console.log("Failed to create core value");
          return;
        }
        updateArrayItem(coreValue, true); 
      } catch (error) {
        setError("You've exceeded the maximum number of core values for your plan.\nPlease upgrade to add more core values.");
      }
      
      return;
    }

    const succees = await updateCoreValue(coreValue);
    if(!succees){
        console.log("Failed to update core value");
        return;
    } 

    updateArrayItem(coreValue);
  }

  const handleDelete = async (id: number) => {
    const deleted = await deleteCoreValue(id);
    if (!deleted) {
      console.log("Failed to delete core value");
      return;
    }

    setCoreValues(coreValues.filter((cv) => cv.id !== id));
  };

  return (
    <AppLayout navigation title="Your Core Values">
      <div className="flex flex-col gap-3">
        {coreValues.map((cv) => (
          <SimpleTextCard
            key={cv.id}
            item={cv}
            date={cv.updatedAtUtc || ""}
            handleAdd={() => {}}
            handleEdit={handleUpdateCoreValue}
            handleDelete={handleDelete} 
          />
           ))}
      </div>
      <div className="flex items-center justify-center mt-8">
        {!!error ? 
          <p className="text-red-500">{error}</p> :
          <button
            className="h-10 w-10"
            onClick={handleNewCoreValue} // Replace with your actual click handler
            aria-label="Add new item"
          >
            <img src={NewItemIcon} alt="" className="h-10 w-10" aria-hidden="true" />
          </button>
        }
      </div>
      
    </AppLayout>
  );
};

export default CoreValues;





