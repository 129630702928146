import React, { useEffect, useRef } from "react";
import DeleteIcon from "assets/images/delete.svg";
import EditIcon from "assets/images/edit.svg";
import SaveIcon from "assets/images/save.svg";

interface TextItem {
    id: number | null;
    value: string;
}

const SimpleTextCard = <T extends TextItem>(props: { item: T; date: string; startEdit?: boolean; createNew?: boolean; handleAdd: (item: T) => void; handleEdit: (item: T) => void; handleDelete: (itemID: number) => void; }) => {
    const [isEdit, setIsEdit] = React.useState((props.startEdit || false));
    const [updatedItem, setItem] = React.useState(props.item.value);

    const inputRef = useRef<HTMLInputElement>(null);
  
    useEffect(() => {
      setItem(props.item.value);
    }, [props.item.value]);

    useEffect(() => {
      if (isEdit) {
        focusInput();
      }
    }, [isEdit]);

    const focusInput = () => {
      inputRef.current?.focus();
    };

    const handleDefocus = (e: any) => {
      if(isEdit){
        e.stopPropagation();
        setIsEdit(false);
        props.handleEdit({...props.item, ID: props.item.id, value: updatedItem });
      }
    };
    
    return (
      <div
        className={'cursor-pointer max-w-[350px] lg:max-w-[400px] app-card rounded-[20px] px-6 py-5 flex flex-col justify-between w-full transition-all duration-300 shadow-lg'}
        onClick={e => handleDefocus(e)}
      >
        <div className="flex flex-col h-full">
          { isEdit ? (
            <div>
              <input
              type="text"
              value={updatedItem}
              onChange={(e) => setItem(e.target.value)}
              ref={inputRef}
              className="text-base font-semibold text-center text-gray-800 border border-gray-300 rounded"
            />
            </div>
          ) : (
            <div>
              <h2 className="text-base font-semibold text-center text-gray-800">
                {props.item.value}
              </h2>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between mt-4">
          <img
            src={DeleteIcon}
            alt="delete"
            className="w-5 h-5 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              const itemID = props.item.id || -1;
              console.log('Deleting item:', props.item); 
              props.handleDelete(itemID);
            }}
          />
          <p className="text-sm text-center text-gray-500">{convertDate(props.date)}</p>
          {isEdit ? (
            <button
              className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                props.handleEdit({...props.item, ID: props.item.id, value: updatedItem });
                setIsEdit(false);
              }}
            >
              <img src={SaveIcon} alt="Save" className="w-5 h-5" />
            </button>
          ) : (
            <button
              className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                setIsEdit(true);
              }}
            >
              <img src={EditIcon} alt="Edit" className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    );
  };
  
const convertDate = (dateStr: string) => {
    if(!dateStr) return "now";
    const [date] = dateStr.split('T');
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
};

export default SimpleTextCard;
  